$black: #000;
$bg-black: rgb(40, 44, 53);
// $black: #0a1931;
$black-800: #374151;
$white: rgba($white, 0.88);
$gray-300: rgb(229, 231, 235);
$gray: #777;
$light-gray: #fafafa;
$light-green: #a9f1df;
$green: #9e8a;
$dark-green: #3c9c84;
$blue: #1094d5;
$dark-blue: #434ff5;
$pink: rgb(180, 142, 173);
$sunny: #fceb4a;
$moon: #f4f1c9;

$nord-blue: #6a85a0;
$nord-white: rgb(248, 248, 242);
$nord-bg-white: #f1f5f9;
$nord-green: rgb(163, 190, 140);
$nord-gray: rgb(99, 111, 136);
$nord-dark: rgb(46, 52, 64);
$nord-light-gray: rgba($gray-300, 0.6);
$nord-pink: rgb(180, 142, 173);

$tablet: 'min-width: 768px';
$desktop: 'min-width: 1024px';

$primary-color: $nord-blue;
$primary-hover-color: rgba($primary-color, 0.8);
